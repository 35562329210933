import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { isEmpty, some } from 'lodash-es'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import {
  AchievementResource,
  CommunityResource
} from '@rallycry/api-suite-typescript/dist/models'
import { InboxMessageDisplay } from './InboxMessageDisplay'
import { useUserInboxMessages } from '@/entity/user/useUserInboxMessages'
import { useTwitchOnline } from '@/entity/site/useTwitchOnline'
import { Unarray } from '@/core/generic'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { NoResults } from '@/components/molecules/text/NoResults'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { InfiniteScrollV2 } from '@/components/atoms/InfiniteScrollV2'
import { calcElevationString } from '@/style/palette'

export const NotificationContainer = ({
  onAchievement
}: {
  onAchievement: (
    achievement: AchievementResource,
    community?: CommunityResource
  ) => void
}) => {
  const {
    hasMore,
    messages,
    query: { isValidating, size, setSize }
  } = useUserInboxMessages()
  const { cfgTwitchChannels } = useFeatures()
  const online = useTwitchOnline({
    request: { identifiers: (cfgTwitchChannels as string)?.split(',') },
    suspense: true
  })

  type MessageType = Unarray<typeof messages>

  // group messages by "today", "yesterday" , "this week" and "older"
  const groupedMessages = useMemo(() => {
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'day').startOf('day')
    const thisWeek = moment().startOf('week')
    const lastWeek = moment().subtract(1, 'week').startOf('week')

    const grouped = {
      twitch: [] as MessageType[],
      today: [] as MessageType[],
      yesterday: [] as MessageType[],
      thisWeek: [] as MessageType[],
      lastWeek: [] as MessageType[],
      older: [] as MessageType[]
    }

    Object.keys(online || {})?.forEach((key: any) => {
      const value = online[key]
      if (value?.stream?.id) {
        grouped.twitch.push({
          id: value?.stream?.id as any,
          state: { ...value?.stream, name: key },
          code: 'twitch.stream.live'
        })
      }
    })

    messages?.forEach(message => {
      const date = moment(message.dateCreated)
      if (date.isSame(today, 'day')) {
        grouped.today.push(message)
      } else if (date.isSame(yesterday, 'day')) {
        grouped.yesterday.push(message)
      } else if (date.isSame(thisWeek, 'week')) {
        grouped.thisWeek.push(message)
      } else if (date.isSame(lastWeek, 'week')) {
        grouped.lastWeek.push(message)
      } else {
        grouped.older.push(message)
      }
    })
    return grouped
  }, [messages, online])

  const displayGroup = (key: keyof typeof groupedMessages) => {
    const messages = groupedMessages[key]
    if (isEmpty(messages)) return null
    return (
      <>
        <Typography variant='h4' pl={5}>
          {
            {
              twitch: <RcTrans i18nKey='shared.twitch' />,
              today: <RcTrans i18nKey='shared.today' />,
              yesterday: <RcTrans i18nKey='shared.yesterday' />,
              thisWeek: <RcTrans i18nKey='shared.this-week' />,
              lastWeek: <RcTrans i18nKey='shared.last-week' />,
              older: <RcTrans i18nKey='shared.older' />
            }[key]
          }
        </Typography>
        <Box px={2}>
          {messages?.map(message => (
            <Box key={message.id}>
              <RcSuspense height={40}>
                <InboxMessageDisplay
                  variant='body2'
                  message={message}
                  onAchievement={onAchievement}
                  render={message => <ListItemButton>{message}</ListItemButton>}
                />
              </RcSuspense>
            </Box>
          ))}
        </Box>
        <Divider />
      </>
    )
  }

  return (
    <RcSuspense>
      {/* Messages to display */}
      <Box
        sx={theme => ({
          padding: 0,
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.text.disabled,
            backgroundImage: calcElevationString(theme.palette.mode, [1])
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.text.primary,
            backgroundImage: calcElevationString(theme.palette.mode, [2])
          }
        })}
      >
        <Paper>
          <Typography variant='h2' p={5} pt={2}>
            <RcTrans i18nKey='shared.notifications' />
          </Typography>
          <Stack direction='column' spacing={3}>
            {displayGroup('twitch')}
            {displayGroup('today')}
            {displayGroup('yesterday')}
            {displayGroup('thisWeek')}
            {displayGroup('lastWeek')}
            {displayGroup('older')}
            {!isValidating && !some(messages) ? <NoResults /> : null}
            <InfiniteScrollV2
              hasMore={hasMore}
              isLoading={isValidating}
              onLoad={() => setSize(size + 1)}
            />
            <Box />
          </Stack>
        </Paper>
      </Box>
    </RcSuspense>
  )
}
