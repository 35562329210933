import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { SocialGridItem } from '@/components/molecules/text/SocialGridItem'
import { ChatMobile } from '@/components/pages/Chat/_ChatMobile'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RootRoute } from '@/core/route-keys'
import { isDarkMode } from '@/core/utils'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useOrganizationUrls } from '@/entity/organization/useOrganizationUrls'
import { useWindowScroll } from '@mantine/hooks'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Toolbar from '@mui/material/Toolbar'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { NotificationIcon } from '../site/NotificationIcon'
import { SignUpButton } from '../site/SignupButton'
import { NavigationAvatar } from './NavigationAvatar'
import { NavigationLayout } from './NavigationLayout'
import { NavigationLink } from './NavigationLink'
import { NavigationOrganization } from './NavigationOrganization'

const iOS =
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent)

/**
 * Container for visual presentation of Mobile navigation (appbar / drawer).
 */
export const NavigationMobileV4 = ({
  skipAlerts
}: {
  skipAlerts?: boolean
}) => {
  const { getPath } = useNavigation()
  const [leftDrawer, setLeftDrawer] = useState(false)
  const location = usePathname()
  const { user } = useFirebase()
  const { profile } = useOrganization()
  const { urls } = useOrganizationUrls()
  const { featCMSOnly, featChat, featChatSupport } = useFeatures()

  const toggleLeftDrawer = () => {
    setLeftDrawer(s => !s)
  }

  // close drawer on nav
  useEffect(() => {
    if (location) {
      setLeftDrawer(false)
    }
  }, [location])

  const [scroll] = useWindowScroll()

  return (
    <>
      <HideOnScroll>
        <AppBar
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 1)'
          }}
        >
          <Box
            sx={theme =>
              scroll.y < 150
                ? {
                    '& .MuiIconButton-root, & .MuiTypography-root': {
                      color: isDarkMode(theme)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary
                    },
                    '&:hover': {
                      '& .MuiIconButton-root, & .MuiTypography-root': {
                        color: isDarkMode(theme)
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary
                      },
                      transition: theme.transitions.create(
                        ['background-color'],
                        {
                          duration: theme.transitions.duration.leavingScreen
                        }
                      )
                    },
                    backgroundColor: 'transparent',
                    transition: theme.transitions.create(['background-color'], {
                      duration: theme.transitions.duration.enteringScreen
                    })
                  }
                : {
                    backgroundColor: theme.palette.background.default,
                    transition: theme.transitions.create(['background-color'], {
                      duration: theme.transitions.duration.leavingScreen
                    })
                  }
            }
          >
            <Toolbar variant='dense' sx={{ paddingLeft: { xs: 2 } }}>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={1}
              >
                <Grid item>
                  <RcIconButton
                    icon={['fal', 'bars']}
                    onClick={toggleLeftDrawer}
                    size='small'
                  />
                </Grid>
                <Grid item xs>
                  <NavigationOrganization transparent large={false} />
                </Grid>
                <Grid item>
                  <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    spacing={2}
                  >
                    <Hidden smDown>
                      <SocialGridItem
                        network={NetworkKind.DISCORD}
                        url={profile?.primaryDiscordInviteUrl}
                      />
                      {urls
                        ?.filter(it => it.options !== 'highlight')
                        ?.map(it => (
                          <SocialGridItem
                            key={it.url}
                            network={it.network!}
                            url={it.url}
                            description={it.description}
                          />
                        ))}
                    </Hidden>

                    {user && !user.isAnonymous ? (
                      <>
                        {skipAlerts ? null : (
                          <>
                            {featChat || featChatSupport ? (
                              <RcSuspense skipLoader skipError>
                                <ChatMobile />
                              </RcSuspense>
                            ) : null}
                            <RcSuspense skipLoader skipError>
                              <NotificationIcon />
                            </RcSuspense>
                          </>
                        )}
                        <RcSuspense skipLoader skipError>
                          <NavigationAvatar avatarOnly />
                        </RcSuspense>
                      </>
                    ) : !featCMSOnly ? (
                      <>
                        <NavigationLink
                          to={getPath({ root: RootRoute.Login })}
                          underline='none'
                        >
                          <RcButton color='secondary' sx={{ height: '100%' }}>
                            <RcTrans i18nKey='shared.login' />
                          </RcButton>
                        </NavigationLink>
                        <SignUpButton
                          variant='contained'
                          sx={{ height: '100%' }}
                        />
                      </>
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>
            </Toolbar>
          </Box>
        </AppBar>
      </HideOnScroll>
      <SwipeableDrawer
        variant='temporary'
        anchor='left'
        elevation={0}
        open={leftDrawer}
        onOpen={toggleLeftDrawer}
        onClose={toggleLeftDrawer}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        disableSwipeToOpen
        keepMounted={false}
      >
        <NavigationLayout skipAlerts />
      </SwipeableDrawer>
    </>
  )
}

const HideOnScroll = ({
  children
}: {
  children: React.ReactElement<any, any>
}) => {
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}
