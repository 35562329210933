import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { TypographyProps } from '@mui/material/Typography'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { usePathname } from 'next/navigation'
import { PageItem } from '../page/PageItem'
import { NavigationLink } from '../navigation/NavigationLink'
import { NavigationOrganization } from '../navigation/NavigationOrganization'
import { Copyright } from './Copyright'
import { DiscordPrompt } from './DiscordPrompt'
import { SignUpButton } from './SignupButton'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { SocialGridItem } from '@/components/molecules/text/SocialGridItem'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'
import { RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useOrganizationUrls } from '@/entity/organization/useOrganizationUrls'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { SiteLocale } from '@/components/pages/Settings/components/SiteLocale'

export const Footer = () => {
  const {
    featCMSOnly,
    featRallyCryFooter,
    featElasticResources,
    featContactLink
  } = useFeatures()
  const isMobile = useIsMobile()
  const { account } = useUserAccount()
  const { getNetworkString } = useNetworkKinds()
  const { urls } = useOrganizationUrls()
  const { getPath } = useNavigation()
  const { organization, profile } = useOrganization()

  const typographyProps: TypographyProps = {
    align: isMobile ? 'center' : 'left',
    variant: 'body2',
    color: 'text.primary'
  }

  return (
    <footer style={{ position: 'relative' }}>
      <PageItem
        sx={{
          border: 'none',
          borderRadius: 0
        }}
      >
        <Container maxWidth='lg'>
          <Grid
            container
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'center' : 'flex-start'}
            justifyContent='space-between'
            spacing={5}
            mt={10}
            mb={5}
          >
            <Grid item xs={12} sm={6}>
              <Stack
                spacing={3}
                alignItems={isMobile ? 'center' : 'flex-start'}
                direction='column'
              >
                <NavigationOrganization transparent displayText large />
                <Stack
                  direction='row'
                  spacing={1}
                  sx={{ pl: 2 }}
                  alignItems='center'
                >
                  {account || featCMSOnly ? null : <SignUpButton />}
                  <SiteLocale />
                  {profile?.primaryDiscordInviteUrl ? (
                    <SocialGridItem
                      network={NetworkKind.DISCORD}
                      url={profile?.primaryDiscordInviteUrl}
                      tooltip={<RcTrans i18nKey='networks.DISCORD' />}
                    />
                  ) : null}
                  {urls
                    ?.filter(it => it.options == 'none' || !it.options)
                    ?.map(it => (
                      <SocialGridItem
                        key={it.network}
                        network={it.network!}
                        url={it.url}
                        tooltip={
                          it.name ? it.name : getNetworkString(it.network)
                        }
                        description={it.description}
                      />
                    ))}
                </Stack>
                {!isMobile ? (
                  <>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      sx={{ pl: 3 }}
                    >
                      <Copyright />
                    </Typography>
                  </>
                ) : null}
              </Stack>
            </Grid>

            <Grid item>
              <Stack spacing={4} direction='column'>
                <Typography {...typographyProps} variant='h4'>
                  <RcTrans i18nKey='site.footer.title' />
                </Typography>
                {account ? (
                  <NavigationLink to={getPath({ root: RootRoute.Root })}>
                    <Typography {...typographyProps}>
                      <RcTrans i18nKey='site.footer.home' />
                    </Typography>
                  </NavigationLink>
                ) : (
                  <NavigationLink to={getPath({ root: RootRoute.Landing })}>
                    <Typography {...typographyProps}>
                      <RcTrans i18nKey='site.footer.landing' />
                    </Typography>
                  </NavigationLink>
                )}
                {featContactLink ? (
                  <NavigationLink href={featContactLink as string}>
                    <Typography {...typographyProps}>
                      <RcTrans
                        i18nKey='site.footer.contact'
                        tOptions={{ organization: organization?.name }}
                      />
                    </Typography>
                  </NavigationLink>
                ) : null}
                <DiscordPrompt>
                  <Typography {...typographyProps}>
                    <RcTrans i18nKey='site.navigator.discord' />
                  </Typography>
                </DiscordPrompt>
                {!account && !featCMSOnly && (
                  <NavigationLink to={getPath({ root: RootRoute.Register })}>
                    <Typography {...typographyProps}>
                      <RcTrans i18nKey='shared.register' />
                    </Typography>
                  </NavigationLink>
                )}
              </Stack>
            </Grid>
            {featRallyCryFooter ? (
              <Grid item>
                <Stack spacing={4} direction='column'>
                  <Typography {...typographyProps} variant='h4'>
                    <RcTrans i18nKey='site.footer.rallycry' />
                  </Typography>
                  <Link
                    href='https://about.rallycry.gg'
                    target='_new'
                    rel='noopener'
                  >
                    <Typography {...typographyProps}>
                      <RcTrans i18nKey='site.footer.website' />
                    </Typography>
                  </Link>
                  <Link
                    href='https://about.rallycry.gg/contact'
                    target='_new'
                    rel='noopener'
                  >
                    <Typography {...typographyProps}>
                      <RcTrans i18nKey='site.footer.jobs' />
                    </Typography>
                  </Link>
                </Stack>
              </Grid>
            ) : null}
            <Grid item>
              <Stack spacing={4} direction='column'>
                <Typography {...typographyProps} variant='h4'>
                  <RcTrans i18nKey='site.footer.resources' />
                </Typography>
                <ContactFlowLink>
                  <Typography {...typographyProps}>
                    <RcTrans i18nKey='shared.get-help-button' />
                  </Typography>
                </ContactFlowLink>
                {featElasticResources ? (
                  <NavigationLink to={getPath({ root: RootRoute.Resources })}>
                    <Typography {...typographyProps}>
                      <RcTrans i18nKey='site.footer.guides' />
                    </Typography>
                  </NavigationLink>
                ) : null}
                <NavigationLink to={getPath({ root: RootRoute.Privacy })}>
                  <Typography {...typographyProps}>
                    <RcTrans i18nKey='site.footer.privacy' />
                  </Typography>
                </NavigationLink>
                <NavigationLink to={getPath({ root: RootRoute.TermsOfUse })}>
                  <Typography {...typographyProps}>
                    <RcTrans i18nKey='site.footer.terms' />
                  </Typography>
                </NavigationLink>
                {account ? (
                  <NavigationLink to={getPath({ root: RootRoute.Settings })}>
                    <Typography {...typographyProps}>
                      <RcTrans i18nKey='site.footer.account-settings' />
                    </Typography>
                  </NavigationLink>
                ) : null}
              </Stack>
            </Grid>
            {isMobile ? (
              <>
                <Grid item>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    textAlign='center'
                  >
                    <Copyright />
                  </Typography>
                </Grid>
                <Grid item>
                  <SiteLocale />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Container>
      </PageItem>
    </footer>
  )
}
